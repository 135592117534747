@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

:root {
  --custom-padding: 1.5rem;
  /* 6 */
  --custom-padding-md: 2rem;
  /* 8 */
  --custom-padding-lg: 5rem;
  /* 20 */
}


body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  padding-right: 0 !important;
    height: 100%;
      width: 100vw;
      overflow-x: hidden;
}

.home {
 
}


@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}